import React from "react";
import {css} from "@emotion/react";

import {NAVIGATION_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {BackTo} from "./BackTo";
import {BurgerMenu} from "./BurgerMenu";

interface IProps {
    logo: {
        imgUrl: string;
        url: string;
        alt: string;
        title: string;
    };
    middleElement?: JSX.Element;
    arrows: {
        left: JSX.Element;
    };

    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    onBack: () => void;
    label: string;
    navState: number[];

    backToUrl?: {
        url: string;
        text: string;
    };
    isMobile?: boolean;
    mobileBreakpoint?: number;
}

export const LogoAndBurger = (props: IProps) => {
    const {logo, arrows, isOpen, setOpen, setClose, onBack, label, navState} = props;
    return (
        <nav css={logoAndBurgerStyles}>
            <a css={logoHolder} href={logo.url}>
                <img css={logoStyle} src={logo.imgUrl} alt={logo.alt} title={logo.title} width={440} height={100} data-testid={NAVIGATION_TEST_IDS.MAIN_LOGO} />
            </a>

            {props.isMobile && props.backToUrl && props.backToUrl?.url !== "" ? <BackTo backToUrl={props.backToUrl} /> : null}

            {props.middleElement && props.middleElement}

            <BurgerMenu
                isOpen={isOpen}
                setOpen={setOpen}
                setClose={setClose}
                onBack={onBack}
                label={label}
                navState={navState}
                arrow={arrows.left}
                mobileBreakpoint={props.mobileBreakpoint}
            />
        </nav>
    );
};

const logoAndBurgerStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const logoHolder = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.8rem;
`;

const logoStyle = css`
    width: auto;
    height: auto;
    max-width: 180px;
    max-height: 40px;
`;
